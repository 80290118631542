import { AbilityBuilder } from '@casl/ability';

export default function defineRulesFor(data) {
    try {
        const { can, cannot, rules } = new AbilityBuilder();

        for (let row of data) {
            const model = row['model'].toLowerCase(),
                action = row['action'].toLowerCase();
            if (row['status']) {
                if (!row['ids'] && !row['excluded_ids']) {
                    can(action, model);
                }

                if (row['ids']) {
                    can(action, model, { 'id': { $in: row['ids'] } });
                }

                if (row['excluded_ids']) {
                    cannot(action, model, { 'id': { $in: row['excluded_ids'] } });
                }
            }
            else {
                if (!row['ids'] && !row['excluded_ids']) {
                    cannot(action, model);
                }

                if (row['ids']) {
                    cannot(action, model, { 'id': { $in: row['ids'] } });
                }

                if (row['excluded_ids']) {
                    can(action, model, { 'id': { $in: row['excluded_ids'] } });
                }
            }
        }
        return rules;
    } catch (e) {
        console.log('defineRulesFor Error',e)
    }
}