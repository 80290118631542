<template>
  <v-stepper class="elevation-0">
    <v-stepper-header>
      <!-- Step 1: Created -->
      <v-stepper-item
          :value="1"
          :complete="true"
          :color="getColor(true)"
          :title="$store.getters.translate('created')"/>
      <v-divider/>
      <!-- Step 2: Assigned -->
      <v-stepper-item
          :value="2"
          :complete="assigned"
          :color="getColor(assigned)"
          :title="$store.getters.translate('assigned')"/>
      <v-divider/>
      <!-- Step 3: Active -->
      <v-stepper-item
          :value="3"
          :complete="active"
          :color="getColor(active)"
          :title="$store.getters.translate('active')"/>
      <v-divider/>
      <!-- Step 4: Closed -->
      <v-stepper-item
          :value="4"
          :complete="closed"
          :color="getColor(closed)"
          :title="$store.getters.translate('closed')"/>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  props: ["active", "assigned", "closed"],
  methods: {
    getColor(status) {
      return status ? "primary" : "#9E9E9E";
    },
  },
};
</script>