<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="closeModal(modal_name)">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ $store.getters.translate('select_root_asset') }}</v-toolbar-title>
            <v-spacer/>
            <v-btn :disabled="loading" size="small" @click="importInspection">
              <v-icon icon="mdi-import" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
          <v-card-text class="ml-5 mr-5">
            <v-autocomplete
                variant="underlined"
                bg-color="white"
                color="primary"
                :items="root_assets"
                v-model="root_asset_id"
                :label="$store.getters.translate('select_root_asset')"
                item-title="name"
                item-value="id"/>
            <v-text-field
                color="primary"
                variant="underlined"
                :label="$store.getters.translate('gen_project_number')"
                v-model="project_number">
              <template #label>{{ $store.getters.translate('gen_project_number') }}<span class="text-red"><strong>*</strong></span></template>
            </v-text-field>
            <v-file-input
                class="mt-3"
                :label="$store.getters.translate('files')"
                show-size
                ref="file_input"
                v-model="file"/>
          </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  props: ["modal_name"],
  data() {
    return {
      loading: false,
      root_assets: [],
      root_asset_id: null,
      project_number: null,
      file: null
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.root_assets = [];
      this.root_asset_id = null;
      this.project_number = null;
      this.file = null;
      this.openModal(this.modal_name);
      this.loadRootAssets();
    },
    async loadRootAssets() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/assets?root=true")
          .then((response) => {
            this.loading = false;
            this.root_assets = response.data;
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    importInspection() {
      if(!this.loading) {
        if(this.file) {
          if(this.project_number) {
            this.loading = true;
            const formData = new FormData();
            formData.append("attachment", this.file);
            formData.append("root_asset_id", this.root_asset_id);
            formData.append("project_number", this.project_number);
            this.$http
                .post(this.$store.getters.appUrl + "v2/import-inspection", formData, {headers: {"Content-Type": "multipart/form-data"}})
                .then(() => {
                  this.loading = false;
                  this.$emit('refresh');
                })
                .catch((error) => {
                  this.$toast.error(error.message);
                  this.loading = false;
                });
          }
          else {
            this.$toast.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate('gen_project_number'));
          }
        }
        else {
          this.$toast.error(this.$store.getters.translate("file_not_found"));
        }
      }
    },
  }
};
</script>