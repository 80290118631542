import axios from 'axios';

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const {response} = error;

        switch (+response.status){
            case 422: {
                let errorMessages = response.data.errors, errors = Object.keys(response.data.errors);
                app.config.globalProperties.$toast.error(errorMessages[errors[0]])
                break;
            }
            default: {
                console.error(error);
            }
        }

        return Promise.reject(error);
    },
);

export default axios;