<template>
  <v-dialog v-model="visible" persistent max-width="400px">
    <v-card v-click-outside="cancel">
      <v-card-title v-if="title">{{ title }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="green-darken-1" variant="text" style="font-weight:bold;" @click="cancel()">NO</v-btn>
        <v-btn color="green-darken-1" variant="text" style="font-weight:bold;" @click="confirm()">YES</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    message: String,
  },
  emits: ['close', 'confirm', 'cancel'],
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    confirm() {
      this.$emit('confirm');
      this.closeDialog();
    },
    cancel() {
      this.$emit('cancel');
      this.closeDialog();
    },
    closeDialog() {
      this.visible = false;
      this.$nextTick(() => {
        this.$emit('close');
      });
    }
  },
  mounted() {
    this.visible = true;
  },
};
</script>