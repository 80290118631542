<template>
  <div class="mb-5">
    <small>{{ $store.getters.translate(label) }}</small>
    <div style="margin-top: 20px" class="float-right">
      <v-icon v-if="label !== 'file' && !hide_copy" @click="copy" icon="mdi-clipboard-text-outline"/>
      <v-icon v-if="deletable" @click.stop="$emit('delete', modelValue)" color="red" icon="mdi-trash-can"/>
    </div>
    <span class="value" v-if="!link">{{ getValue }}</span>
    <a class="link" v-else>{{ getValue }}</a>
    <v-divider/>
  </div>
</template>

<script>
export default {
  props: [
    "label",
    "modelValue",
    "link",
    "deletable",
    "hide_copy",
  ],
  methods: {
    copy() {
      this.$clipboard(this.modelValue);
      this.$toast.info(this.$store.getters.translate("copied_to_clipboard"));
    },
  },
  computed: {
    getValue() {
      if (!this.modelValue) {
        return "-";
      }
      return this.modelValue;
    },
  },
};
</script>

<style scoped>
.base-row {
  margin-bottom: 12px;
}
.base-row small {
  display: block;
}

.value {
  display: block;
  color: #525252;
  font-size: 16px;
  margin-bottom: 8px !important;
}

.link {
  display: block !important;
  font-size: 16px;
  margin-bottom: 8px !important;
}
</style>