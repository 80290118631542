import { createApp, h } from 'vue';
import ConfirmDialog from '../components/commonComponents/ConfirmDialog.vue';
import vuetify from './vuetify';

export default {
    install: (app) => {
        app.config.globalProperties.$confirm = function (message, title) {
            return new Promise((resolve) => {
                const container = document.createElement('div');
                document.body.appendChild(container);

                const ConfirmComponent = createApp({
                    render() {
                        return h(ConfirmDialog, {
                            title,
                            message,
                            onConfirm: () => {
                                resolve(true);
                            },
                            onCancel: () => {
                                resolve(false);
                            },
                            onClose: () => {
                                // Wait for Vue to finish updates before removing
                                this.$nextTick(() => {
                                    ConfirmComponent.unmount();
                                    document.body.removeChild(container);
                                });
                            }
                        });
                    },
                });

                ConfirmComponent.use(vuetify);
                ConfirmComponent.mount(container);
            });
        };
    },
};
