export default {
  install: (app) => {
    app.config.globalProperties.$Tawk = {
      $isTawkUrlAssign: function () {
        return process.env.VUE_APP_TAWK_URL ? true : false;
      },
      $createChat: function () {
        let Tawk_API = {};
        let Tawk_LoadStart = new Date();
        let s1 = document.createElement("script");
        let s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = process.env.VUE_APP_TAWK_URL;
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
        window.Tawk_API = Tawk_API;
        return Tawk_LoadStart;
      },
      $updateChatUser: function (user) {
        if (!user) return;
        window.Tawk_API.onLoad = function () {
          window.Tawk_API.setAttributes({
            'name': user.name,
            'email': user.email,
            'hash': user.emailHmac
          }, function (error) {
            console.log(error);
          });
        };
      },
      $endChat: function () {
        window.Tawk_API.endChat();
      },
      $hideWidget: function () {
        window.Tawk_API.hideWidget();
      },
      $isInit: function () {
        return !!window.Tawk_API;
      }
    };
  }
};
