<template>
  <v-list-item
      v-if="canShow(item)"
      :href="href"
      :to="item.to"
      base-color="black"
      class="pa-3"
      active-class="secondary white--text">
    <template v-if="item.icon" #prepend>
      <v-icon :icon="item.icon"/>
    </template>
    <template #title>
      {{ item.title }}
    </template>
  </v-list-item>
</template>

<script>
import {useAbility} from "@casl/vue";
export default {
  name: "BaseItem",
  setup() {
    const { can } = useAbility();
    return {
      can
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        title: undefined,
        to: undefined,
      }),
    },
    text: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    canShow(item) {
      if (item.gate.subject === "dashboard" || item.gate.subject === "documentation") {
        return true;
      }
      if (item.gate) {
        return this.can(item.gate.action, this.$createEntity(item.gate.subject, {id: 0}));
      }
    },
  },
  computed: {
    href() {
      return this.item.href || (!this.item.to ? "#" : undefined);
    },
  },
};
</script>