<template>
    <v-btn size="small" @click="openModal(modal_name); showHandler()">
      {{ $store.getters.translate("send_login_credentials") }}
    </v-btn>
    <BaseDraggableModal
        :name="modal_name"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.display.xsOnly"
        :ref="modal_name">
      <v-card :disabled="loading" :loading="loading">
        <BaseModal>
          <template #header>
            <v-toolbar class="draggable_selector" color="primary" dense flat>
              <v-toolbar-title>{{ $store.getters.translate("send_login_credentials") }}</v-toolbar-title>
              <v-spacer/>
              <v-btn size="small" @click="closeModal(modal_name); showHandler()">
                <v-icon icon="mdi-close" size="x-large"/>
              </v-btn>
            </v-toolbar>
          </template>
          <template #content>
            <v-card-text class="ml-5 mr-5">
              <v-text-field
                variant="underlined"
                :disabled="loading"
                :label="$store.getters.translate('email')"
                v-model="record.email"
                counter
                required
                validate-on="blur"
                :rules="emailRules"/>
              <base-message :label="$store.getters.translate('message')" :modelValue="record.message" modal_name="base_message"/>
            </v-card-text>
          </template>
          <template #footer>
            <v-card-actions>
              <v-spacer/>
              <v-btn :disabled="isDisabled" color="primary" variant="text" @click="save()">
                {{ $store.getters.translate("send") }}
              </v-btn>
            </v-card-actions>
          </template>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  props: ["person_id", "user_type", "emailaddress", "person", "modal_name"],
  data() {
    return {
      loading: false,
      does_not_exist: true,
      emailRules: [
        (v) => !!v || this.$store.getters.translate("please_submit_valid_email"),
        (v) => /.+@.+\..+/.test(v) || this.$store.getters.translate("please_submit_valid_email"),
        (v) => this.check(v) || this.$store.getters.translate("please_submit_valid_email"),
        () => this.does_not_exist || this.$store.getters.translate("email_exists"),
      ],
      record: {
        email: this.emailaddress,
        phonenumber: null,
        person_id: this.person_id,
        user_type: this.user_type,
        message: null,
      },
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    save() {
      this.loading = true;
      this.record.person_id = this.person_id;
      this.$http
        .post(this.$store.getters.appUrl + "v2/user/create", this.record)
        .then(() => {
          this.loading = false;
          this.$toast.success(this.$store.getters.translate("the_invitation_has_been_sent"));
          this.$emit("refresh");
          this.closeModal(this.modal_name);
          this.showHandler();
        }).catch(() => {
          this.loading = false;
          this.$toast.error(this.$store.getters.translate("email_taken_or_incorrect"));
        });
    },
    check(value) {
      var validator = require("email-validator");
      let check = validator.validate(value);

      if (!check) {
        return false;
      }

      return true;
    },
    exists() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/emailaddresses/?search=" + this.new_emailaddress.emailaddress)
        .then((response) => {
          let result = false;
          if (response.data.length == 0) {
            result = true;
          }
          this.loading = false;
          this.does_not_exist = result;
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toast.error(error.message);
          }
          this.loading = false;
        });
    },
    showHandler() {
      this.record = {
        email: this.emailaddress,
        person_id: this.person_id,
        user_type: this.user_type,
        phonenumber: this.person.phonenumber,
      };
    },
  },
  computed: {
    isDisabled() {
      if ((this.record.email || this.record.phonenumber) && this.record.person_id && this.record.user_type) {
        return false;
      }
      return true;
    },
  },
};
</script>