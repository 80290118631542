<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
        <v-toolbar class="draggable_selector" color="primary" dense flat>
          <v-btn @click="closeModal(modal_name)">
            <v-icon icon="mdi-close" size="x-large"/>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn v-if="canDelete(record.id || 0)" size="small" @click="archive()">
            <v-icon icon="mdi-trash-can" size="x-large"/>
          </v-btn>
          <v-btn v-if="canEdit(record.id || 0)" size="small" @click="save()">
            <v-icon icon="mdi-content-save" size="x-large"/>
          </v-btn>
        </v-toolbar>
        </template>
        <template #content>
            <v-card-text class="ml-5 mr-5">
              <!--USUAL FIELDS-->
              <base-field
                  v-for="field in usual_fields"
                  :key="field.name"
                  :isDisabled="!canEdit(record.id || 0)"
                  v-model="record[field.name]"
                  :field="field"
                  @change="usualFieldDataChanged"/>
              <!--CUSTOM FIELDS-->
              <base-field
                  v-if="record.custom_fields"
                  v-for="field in custom_fields"
                  :key="field.name"
                  :isDisabled="!canEdit(record.id || 0)"
                  v-model="record.custom_fields[field.name]"
                  :field="field"
                  @change="customFieldDataChanged"/>
              <!--LIST FILES-->
              <template v-if="record.files" v-for="(file, index) in record.files" :key="index">
                <base-row
                    class="mt-3"
                    :label="$store.getters.translate('file')"
                    v-model="file.name"
                    @click="downloadFile(file)"
                    :link="true"
                    :hide_copy="true"
                    :deletable="canEdit(record.id || 0)"
                    @delete="deleteFile(file)"/>
              </template>
              <!--UPLOAD FILES-->
              <v-row v-if="files_required">
                <v-col v-cloak @drop.prevent="addDropFile" @dragover.prevent>
                  <v-file-input
                      :disabled="!canEdit(record.id || 0)"
                      class="mt-3"
                      :label="$store.getters.translate('files')"
                      placeholder="Drop file to upload"
                      show-size
                      v-model="new_files"
                      :multiple="multiple_files"
                      @change="saveFiles"/>
                </v-col>
              </v-row>
              <!--TAGS-->
              <base-tags v-if="tags_required" :isDisabled="!canEdit(record.id || 0)" :modelValue="record.tags" :modal_name="page.name" @change="tagsChanged"/>
            </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import { useAbility } from '@casl/vue'

export default {
  props: ["modal_name", "page", "files_required", "multiple_files"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  setup() {
    const { can } = new useAbility();
    return {
      can
    }
  },
  data() {
    return {
      record: {},
      loading: false,
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      //APPLICATION VARIABLES. NOT USED FOR OTHER MODELS
      application_equipment_group: null,
      application_equipment_category: null,
      application_explosive_atmosphere: null,
      //MARKERING VARIABLES. NOT USED FOR OTHER MODELS
      regulation: null,
      gas_group: null,
      protection_method: null,
      temperature_class: null,
      equipment_protection_level: null,
      //FILES VARIABLE
      new_files: []
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load(id) {
      this.record = {};
      this.new_files = [];
      this.usual_fields = [];
      this.custom_fields = [];
      this.openModal(this.modal_name);
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toast, this.page.name, id);
      if(this.page.model === 'tag') {
        this.record.name = this.record.name.en;
      }
      await this.getFields();
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toast, this.record, this.usual_fields)) {
          this.loading = true;
          if(await helpFunctions.saveModel(this.$toast, this.page.name, this.record)) {
            if(this.page.model === 'translation') {
              await this.$store.dispatch("refreshTranslations");
            }
            this.$emit("refresh");
            this.closeModal(this.modal_name);
          }
          this.loading = false;
        }
      }
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toast, this.page.name, this.record.id)) {
            this.$emit("refresh");
            this.closeModal(this.modal_name);
          }
          this.loading = false;
        }
      });
    },
    usualFieldDataChanged(data) {
      if(this.record[data[0]] !== data[1]) {
        this.record[data[0]] = data[1];
        if (this.page.model === 'application' && (data[0] === 'equipment_group_id' || data[0] === 'equipment_category_id' || data[0] === 'explosive_atmosphere_id')) {
          if (data[0] === 'equipment_group_id') {
            if (data[2]) {
              this.application_equipment_group = data[2].split(" | ")[0]; //data[2] stores selected text value
            } else {
              this.application_equipment_group = null;
            }
          } else if (data[0] === 'equipment_category_id') {
            this.application_equipment_category = data[1]; //data[1] stores selected id (int) value
          } else if (data[0] === 'explosive_atmosphere_id') {
            if (data[2]) {
              this.application_explosive_atmosphere = data[2].split(" | ")[0]; //data[2] stores selected text value
            } else {
              this.application_explosive_atmosphere = null;
            }
          }
          this.record.name = "";
          if (this.application_equipment_group) {
            this.record.name = this.application_equipment_group + " ";
          }
          if (this.application_equipment_category) {
            this.record.name += this.application_equipment_category + " ";
          }
          if (this.application_explosive_atmosphere) {
            this.record.name += this.application_explosive_atmosphere;
          }
        }
        else if (this.page.model === 'markering' && (data[0] === 'regulation_id' || data[0] === 'protection_method_id' || data[0] === 'gas_group_id' || data[0] === 'temperature_class_id' || data[0] === 'equipment_protection_level_id')) {
          if (data[0] === 'regulation_id') {
            if (data[2]) {
              this.regulation = data[2].split(" | ")[0]; //data[2] stores selected text value
            } else {
              this.regulation = null;
            }
          } else if (data[0] === 'protection_method_id') {
            if (data[2]) {
              this.protection_method = data[2].split(" | ")[0]; //data[2] stores selected text value
            } else {
              this.protection_method = null;
            }
          } else if (data[0] === 'gas_group_id') {
            if (data[2]) {
              this.gas_group = data[2].split(" | ")[0]; //data[2] stores selected text value
            } else {
              this.gas_group = null;
            }
          } else if (data[0] === 'temperature_class_id') {
            if (data[2]) {
              this.temperature_class = data[2].split(" | ")[0]; //data[2] stores selected text value
              this.record.maximum_surface_temperature = data[2];
            } else {
              this.temperature_class = null;
              this.record.maximum_surface_temperature = '';
            }
          } else if (data[0] === 'equipment_protection_level_id') {
            if (data[2]) {
              this.equipment_protection_level = data[2].split(" | ")[0]; //data[2] stores selected text value
            } else {
              this.equipment_protection_level = null;
            }
          }
          this.record.name = "";
          if (this.regulation) {
            this.record.name = this.regulation + " ";
          }
          if (this.protection_method) {
            this.record.name += this.protection_method + " ";
          }
          if (this.gas_group) {
            this.record.name += this.gas_group + " ";
          }
          if (this.temperature_class) {
            this.record.name += this.temperature_class + " ";
          }
          if (this.equipment_protection_level) {
            this.record.name += this.equipment_protection_level;
          }
        }
      }
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    tagsChanged(data) {
      this.record.tags = data;
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_update");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.loading = false;
    },
    downloadFile(file) {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/files/" + file.id, { responseType: "blob" })
          .then((response) => {
            this.loading = false;
            const blob = new Blob([response.data], { type: file.content_type });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = file.name;
            link.click();
            URL.revokeObjectURL(link.href);
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    deleteFile(file) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.loading = true;
          this.$http
              .delete(this.$store.getters.appUrl + "v2/files/" + file.id)
              .then(() => {
                this.loading = false;
                this.$toast.success(this.$store.getters.translate("successfully_deleted"));
                this.load(this.record.id);
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toast.error(error.message);
                }
                this.loading = false;
              });
        }
      });
    },
    addDropFile(e) {
      this.uploadFiles(e.dataTransfer.files);
    },
    saveFiles() {
      if (this.new_files) {
        this.uploadFiles(this.new_files);
      }
    },
    uploadFiles(files) {
      this.loading = true;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("attachment[]", files[i]);
      }
      formData.append("id", this.record.id);
      this.$http
          .post(this.$store.getters.appUrl + "v2/" + this.page.name + "/uploadfiles", formData, { headers: { "Content-Type": "multipart/form-data" } })
          .then(() => {
            this.loading = false;
            this.$toast.success(this.$store.getters.translate("successfully_saved"));
            this.load(this.record.id);
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.response?.data?.error ?? error.message);
            }
            this.loading = false;
          });
    },
    canEdit(id = 0) {
      return this.can('edit', this.$createEntity(this.page.model, {id}));
    },
    canDelete(id = 0) {
      return this.can("delete", this.$createEntity(this.page.model, {id}));
    },
  },
};
</script>