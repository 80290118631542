<template>
  <v-btn size="small" @click.stop="openModal(module_name); request()">
    {{ $store.getters.translate(label) }}
    <v-icon color="primary" size="large" class="ml-2" icon="mdi-link"/>
  </v-btn>
  <BaseDraggableModal
      :name="module_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      :ref="module_name">
    <v-card :disabled="loading" :loading="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn @click="reset()">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ $store.getters.translate(module_name) }}</v-toolbar-title>
            <v-spacer/>
            <v-btn size="small" @click="save()">
              <v-icon icon="mdi-content-save" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
          <v-card-text class="ml-5 mr-5">
            <v-text-field
                class="mb-3 mt-3"
                color="primary"
                variant="underlined"
                density="compact"
                bg-color="white"
                v-model="search"
                v-debounce:250ms.lock="launchSearch"
                @click:clear="clearSearch"
                :label="$store.getters.translate('search')"
                single-line
                hide-details
                clearable
                append-inner-icon="mdi-magnify"/>
            <base-tags :modelValue="tags" :modal_name="module_name" @change="searchByTags"/>
            <v-data-table
                :headers="fields"
                :items="getRecords"
                :loading="loading"
                class="mt-5"
                item-value="id"
                selectable-key="id"
                return-object
                show-select
                v-model="selected"
                v-bind="footer_props"
                v-model:items-per-page="options.itemsPerPage"
                v-model:page="options.page">
              <template #body="{ items }">
                <tr v-for="item in items" :key="item.id">
                  <td>
                    <v-checkbox color="primary" v-model="selected" :value="item"/>
                  </td>
                  <td v-for="(key, index) in Object.keys(response_fields)" :key="index">
                    <!--CHECKBOXES/BOOLEAN VALUES-->
                    <template v-if="response_fields[key] === 'boolean'">
                      <template v-if="item[key] === '1' || item[key] === 1 || item[key] === 'true' || item[key] === true">1</template>
                      <template v-else>0</template>
                    </template>
                    <!--DATES-->
                    <template v-else-if="response_fields[key] === 'date' && item[key] && (key === 'date_of_birth' || key === 'created_at' || key === 'updated_at')">
                      {{ moment(item[key], "YYYY-MM-DD").format("DD-MM-YYYY") }}
                    </template>
                    <!--DROPDOWNS-->
                    <template v-else-if="(response_fields[key] === 'select_single' || response_fields[key] === 'select_multiple') && item[key]">
                      <template v-if="Array.isArray(item[key])">
                        <template v-if="item[key].length > 1">
                          <v-chip style="height: auto;" class="mr-2" size="x-small" v-for="elem in item[key]" :key="elem">
                            {{ elem }}
                          </v-chip>
                        </template>
                        <template v-else>
                          {{ $lodash.truncate($store.getters.translate(item[key][0]), { length: 40 }) }}
                        </template>
                      </template>
                      <template v-else>
                        {{ $lodash.truncate($store.getters.translate(item[key]), { length: 40 }) }}
                      </template>
                    </template>
                    <!--LINKS-->
                    <template v-else-if="response_fields[key] === 'link'">
                      <a @click="addToSelect(item)">{{ $lodash.truncate($store.getters.translate(item[key]), { length: 30 }) }}</a>
                    </template>
                    <!--ID FIELDS-->
                    <template v-else-if="key === 'id' || key === 'internal_id'">
                      {{ item[key] }}
                    </template>
                    <!--TEXT FIELDS-->
                    <template v-else>
                      {{ $lodash.truncate($store.getters.translate(item[key]), { length: 30 }) }}
                    </template>
                  </td>
                </tr>
                <tr v-if="getRecords.length === 0">
                  <td class="text-center text-grey" :colspan="fields.length + 1">
                    <span v-if="!loading">{{ $store.getters.translate("nothing_found") }}</span>
                    <span v-else>{{ $store.getters.translate("loading") }}</span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions.js";
import BaseModal from "./BaseModal.vue";
import BaseDraggableModal from "./BaseDraggableModal.vue";

export default {
  components: {BaseDraggableModal, BaseModal},
  props: [
    "module_name",
    "excluded_ids",
    "company_id",
    "label",
  ],
  data() {
    return {
      loading: false,
      records: [],
      selected: [],
      all_records: [],
      options: helpFunctions.table_options,
      footer_props: helpFunctions.footer_props,
      search: "",
      original_fields: {},
      response_fields: {},
      fields: [],
      tags: [],
      selected_model: "",
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    save() {
      this.$emit("change", this.selected);
      this.reset();
    },
    request() {
      if (!this.loading) {
        this.records = [];
        this.loading = true;
        this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.module_name + "?source=site")
          .then((response) => {
            this.loading = false;
            this.all_records = response.data.data;
            this.original_fields = response.data.fields;
            if(this.original_fields.id) {
              delete this.original_fields.id;
            }
            if(this.original_fields.root_asset_id) {
              this.original_fields.root_asset_name = "select_single";
              delete this.original_fields.root_asset_id;
            }
            if (this.search && this.search.length > 2) {
              this.response_fields["search_score"] = "text_field";
            }
            Object.entries(this.original_fields).forEach(([key, value]) => {
              this.response_fields[key] = value;
            });
            this.fields = this.composeFields(this.response_fields);
            if (this.excluded_ids) {
              this.all_records = this.all_records.filter((record) => !this.excluded_ids.includes(record.id));
            }
            this.showRecords();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
      }
    },
    composeFields(fields) {
      let response = [];
      let field_names = Object.keys(fields);
      for (let i = 0; i < field_names.length; i++) {
        response.push({
          title: this.$store.getters.translate(field_names[i]),
          key: field_names[i],
          align: "start",
          sortable: true,
          value: field_names[i],
        });
      }
      return response;
    },
    addToSelect(item) {
      this.selected.push(item);
      this.save();
    },
    showRecords() {
      var sorted_array = [];
      if (this.tags.length > 0) {
        sorted_array = helpFunctions.sortByTagsBase(this.all_records, this.tags);
        if (this.search && this.search.length > 2) {
          sorted_array = helpFunctions.searchInSortedArray(sorted_array, this.response_fields, this.search, 'all', false);
        }
      } else if (this.search && this.search.length > 2) {
        sorted_array = helpFunctions.searchInSortedArray(this.all_records, this.response_fields, this.search, 'all', false);
      } else {
        sorted_array = this.all_records;
      }
      this.records = sorted_array;
    },
    clearSearch() {
      this.search = "";
      if (this.all_records && this.all_records.length > 0) {
        delete this.response_fields["search_score"];
        this.fields = this.composeFields(this.response_fields);
        this.showRecords();
      } else {
        this.request();
      }
    },
    launchSearch() {
      if (this.all_records && this.all_records.length > 0) {
        if (this.search) {
          this.response_fields = {};
          this.response_fields["search_score"] = "text_field";
          Object.entries(this.original_fields).forEach(([key, value]) => {
            this.response_fields[key] = value;
          });
        } else {
          delete this.response_fields["search_score"];
        }
        this.fields = this.composeFields(this.response_fields);
        this.showRecords();
      } else {
        this.request();
      }
    },
    searchByTags(data) {
      this.tags = data;
      if (this.all_records && this.all_records.length > 0) {
        this.showRecords();
      } else {
        this.request();
      }
    },
    reset() {
      this.closeModal(this.module_name);
      this.records = [];
      this.all_records = [];
      this.selected = [];
    },
  },
  computed: {
    getRecords() {
      return this.records;
    },
  },
  watch: {
    options: {
      handler() {
        if (this.all_records && this.all_records.length > 0) {
          this.showRecords();
        } else {
          this.request();
        }
      },
      deep: true,
    },
  },
};
</script>